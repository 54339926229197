button.carousel__button:focus-visible {
  outline: none;
}

.fancybox__content {
  max-width: 60rem;
  padding: 0
}

app-creacion-meeting.fancybox__content {
  padding: 36px;
  width: 80%;

  button.is-close {
    display: none;
  }
}

app-poster-busqueda-avanzada.fancybox__content {
  padding: 18px;
  width: 90%;

  button.is-close {
    display: none;
  }
}

html.with-fancybox body {
  padding-right: 0 !important;
}
