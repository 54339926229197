.swal2-container {

  button {

    &:focus {
      box-shadow: none !important;
    }

    &.swal2-confirm {
      background-color: var(--primary-light) !important;
    }

    &.swal2-cancel {
      background-color: var(--danger) !important;
    }
  }
  
}

@media screen and (max-width: 640px) {
  .swal2-container {
    z-index: 9000 !important;

    &.swal2-center > .swal2-popup {
      // height: 100vh;
      // inset: 0;
      // position: fixed;
      width: 100vw;

      .swal2-icon {
        margin-top: 1rem;
      }

      #swal2-html-container {
        font-size: 1rem;
        margin-top: 0;
      }

      .swal2-label {
        font-size: 0.8rem;
      }

      .swal2-checkbox {
        margin-top: 0;
      }
    }
  }
}
