
.fc-daygrid-event-harness {
  cursor: pointer;
}

.fc-timegrid-bg-harness {
  background-color: var(--primary-light);
  border-radius: 4px;
}

.fc-license-message {
  display: none !important;
}

.fc-timegrid-axis {
  background-color: white !important;
}

.fc-toolbar-title {
  font-size: 1.5rem;
  color: var(--primary-dark);
}

full-calendar th {
  background-color: var(--primary-dark);
  color: white;
}

.fc-timegrid {
  min-height: 390px;
}

.fc-timegrid-slot-label-cushion {
  color: #243646;
}

.fc-v-event {
  border: none !important;
}

.fc-v-event .fc-event-title-container {
  flex-grow: 0 !important;
}

.fc-sticky {
  max-height: initial !important;
}

.fc .fc-scrollgrid .fc-scrollgrid-section-body table tr {
  height: 60px; // Esto es lo que cambia la altura de las filas del full-calendar
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: none !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: none !important;
}

.fc .fc-timegrid-slot-label {
  background-color: var(--primary-clear);
}

.fc-timegrid-slot-label-cushion,
.fc-col-header-cell-cushion {
  color: white;
  font-weight: 100;

  &:hover {
    color: white;
    text-decoration: none;
  }
}

.fc-daygrid-day-number {
  color: var(--primary-dark);
  cursor: pointer;
}

.fc table {
  border-collapse: separate !important;
  border-spacing: 1px !important;
}

.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink {
  border-top: 1px solid white !important;
}

.fc-scrollgrid-sync-inner {
  position: relative;
  /*top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);*/
}


.fc-col-header-cell.fc-resource {
  cursor: pointer;
  padding: 4px;
  vertical-align: middle;

  &:hover {
    background-color: var(--primary-light);
  }
}

.fc-v-event .fc-event-main-frame {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2px;

  display: block !important;
  height: auto !important;

  .fc-event-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.fc-event-main {
  border-bottom: 0.1px solid white;
}

.fc-event-main-frame {
  text-align: center;
}

.fc-timegrid-col-frame {
  cursor: pointer;
}

.fc-agendaWeek-view tr {
  height: 40px;
}

.fc .fc-daygrid-day {
  background-color: #e4eef280;
  border-radius: 8px;
}

// Fav

.fc-event-time {
  svg {
    margin-bottom: 3px;
    margin-left: 4px;
    height: 10px;
    width: 10px;
  }

  &:not(.favorito) {
    svg {
      display: none;
    }
  }
}

.fc-event-title {
  margin-top: -2px;
}

.fc-button-primary {
  background-color: var(--primary-dark) !important;
  border-color: var(--primary-dark) !important;

  &:hover {
    background-color: var(--primary-dark-brilliant) !important;
    border-color: var(--primary-dark-brilliant) !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.fc-day-today {

  &.fc-timegrid-col {
    background-color: var(--grey-clear) !important;
  }

  &.fc-col-header-cell {
    background-color: var(--accent) !important;
  }

  &.fc-daygrid-day {
    background-color: var(--primary-dark) !important;

    .fc-daygrid-day-number {
      color: #fff;
    }
  }
}

.sesion-comun {
  background-color: var(--primary-clear) !important;
}