@import 'calendar';
@import 'chat';
@import 'login';

:root {
  --body: #CCD3D4;
  --solid: #243747;

  --primary: #0000ff;
  --primary-clear: #b7c3cc;
  --primary-shade: #6d92b0;
  --primary-light: #3987cc;
  --primary-dark: #243646;
  --primary-dark-brilliant: #134255;

  --accent: #ffa500;
  --arrow: #42a4f7;

  --danger: #dc3545;

  --grey: #808080;
  --grey-clear: #f4f4f4;
  
  --form: #d4d8db;
  --form-placeholder: #e9ebec;

  --btn-disabled: #c9cdd1;
}
