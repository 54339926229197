@font-face {
  font-family: "Raleway-Regular";
  src: url('../../../assets/fonts/raleway/Raleway-Regular.ttf');
}

@font-face {
  font-family: "Raleway-Medium";
  src: url('../../../assets/fonts/raleway/Raleway-Medium.ttf');
}

@font-face {
  font-family: "Raleway-Bold";
  src: url('../../../assets/fonts/raleway/Raleway-Bold.ttf');
}

@font-face {
  font-family: "Raleway-Semi-Bold";
  src: url('../../../assets/fonts/raleway/Raleway-SemiBold.ttf');
}

@font-face {
  font-family: "Raleway-Italic";
  src: url('../../../assets/fonts/raleway/Raleway-Italic.ttf');
}

@font-face {
  font-family: "Roboto-regular";
  src: url('../../../assets/fonts/Roboto-Regular.woff2');
}

@font-face {
  font-family: "Roboto-medium";
  src: url('../../../assets/fonts/Roboto-Medium.woff2');
}
