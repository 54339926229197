.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #ccd3d4;

  .cssload-speeding-wheel {
    position: absolute;
    top: calc(50% - 3.5px);
    left: calc(50% - 3.5px);
  }
}
.loader,
.loader__figure {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader {
  overflow: visible;
  padding-top: 2em;
  height: 0;

  &__title {
    animation: loader-label 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
    display: block;
    font-size: 1rem;
    margin-top: 1rem;
    text-align: center;
  }
}

.loader__figure {
  height: 0;
  width: 0;
  box-sizing: border-box;
  border: 0 solid #007bff;
  border-radius: 50%;
  -webkit-animation: loader-figure 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-figure 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.logo__onsite {
  margin-left: 50%;
  margin-top: 1rem;
  height: 2rem;

  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);

  -webkit-animation: loader-label 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-label 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-label 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

@-webkit-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #007bff;
  }

  29% {
    background-color: #007bff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-moz-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #007bff;
  }

  29% {
    background-color: #007bff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #007bff;
  }

  29% {
    background-color: #007bff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-webkit-keyframes loader-label {
  0% {
    opacity: 0.25;
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}

@-moz-keyframes loader-label {
  0% {
    opacity: 0.25;
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}

@keyframes loader-label {
  0% {
    opacity: 0.25;
  }
  
  30% {
    opacity: 1;
  }
  
  100% {
    opacity: 0.25;
  }
}
