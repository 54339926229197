:root {
  --chat-top-bar-bg: #243747;
  --chat-top-bar-icon: #bdc3c7;

  --chat-left-bar-bg: #fff;
  --chat-left-bar-color: #000;
  --chat-search-color: #243747;
  --chat-search-bg: #e4eef2;
  
  --chat-pending-bg: #b7c3cc;
  --chat-pending-color: #ff;
  --chat-selected-bg: #007bff;
  --chat-selected-color: #fff;
  
  --chat-bg: #e4eef2;

  --chat-user-bg: #d4dee3;
  --chat-user-title: #000327;
  --chat-user-msg: #000;

  --chat-contact-bg: #fff;
  --chat-contact-title: #0000ff;
  --chat-contact-msg: #000;

  --chat-bottom-bar-bg: #bdc3c7;
  --chat-bottom-bar-icon: #243747;
}
