.form-check-input {
  cursor: pointer;

  &:checked {
    background-color: var(--primary-light);
    border-color: var(--primary-light);
  }

  &:focus {
    border-color: var(--primary);
    box-shadow: 0 0 0 0.25rem var(--primary-clear);
  }
}

textarea, select, input {
  &:focus {
    box-shadow: none !important;
    border-color: var(--primary-light) !important;
  }
}

input, textarea, select {
  outline: none !important;
}

select {
  cursor: pointer;
}

.on-site-form {
  label.on-site-form-label {
    display: flex;
  }

  .on-site-form-control {
    background-color: var(--form-placeholder);
    border: 1px solid var(--form-placeholder);
    border-radius: 4px;
    display: flex;
    width: 100%;
  }
}
