.btn-on-site {
  border: none;
  border-radius: 4px;
  box-shadow: none !important;
  padding: 5px 20px;
  outline: none !important;

  &.btn-on-site-square {
    border-radius: 0;
  }

  &.outline {
    padding: 0;
  }

  // Primary
  &, &:active, 
  &.btn-primary, &.btn-primary:active {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);

    &.outline, &.outline:active {
      color: var(--primary);
      background-color: transparent;
      border-color: transparent;
    }
  }

  // Light
  &.btn-primary-light {
    color: #fff;
    background-color: var(--primary-light);
    border-color: var(--primary-light);

    &.outline {
      color: var(--primary-light);
      background-color: transparent;
      border-color: transparent;
    }
  }

  // Disabled
  &:disabled {
    color: #fff;
    background-color: var(--btn-disabled);
    border-color: var(--btn-disabled);
  }
}