@import './assets/scss/styles';

/* fonts */

body {
  background-color: var(--body) !important;
  font-family: "Raleway-Regular";
  font-size: 14px;
}

.text-bold {
  font-family: "Raleway-Bold";
}

.text-medium {
  font-family: "Raleway-Medium";
}

.text-semi-bold {
  font-family: "Raleway-Semi-Bold";
}

.text-italic {
  font-family: "Raleway-Italic";
}

//
.w-100 {
  width: 100%;
}

/** Ventana emergente */
.ventana__emergente {
  box-shadow: 0 0 20px 4px rgb(0 0 0 / 75%);
  border-radius: 4px;
  position: fixed;
  z-index: 1010;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  max-width: 80%;
  width: 100%;

  .close-container {
    cursor: pointer;
  }
}

.ventana__emergente__header {
  background-color: var(--solid);
  color: white;
  padding: 20px;
}

.ventana__emergente__body {
  padding: 20px;
}

.ventana__emergente__separador {
  background-color: rgba(0, 0, 0, 0.25);

  position: fixed;

  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  height: 100vh;
  width: 100vw;

  z-index: 1005;
}

.mensaje__error {
  color: red;
  font-size: 14px;
}

.boton__estandar {
  border: 1px solid var(--solid);
  background-color: var(--solid);
  border-radius: 5px;
  color: white;
  padding: 10px 15px;

  &:disabled {
    opacity: 0.5
  }
}

button.en__directo, a.en__directo {
  background-color: var(--primary);
  border-radius: 5px;
  color: white;
  cursor: pointer;
  padding: 12px 16px 12px 12px;

  div.spinner-grow {
    width: 1rem;
    height: 1rem;
    margin-right: 8px;
  }

  * {
    cursor: pointer;
  }
}

/* fondos */
.w-100 {
  width: 100%;
}

// Bloqueo scroll

body#bienvenida,
body#hall,
// body#login,
body#recuperar-pass,
body#stand,
// body#sala,
body#eposters,
body#videos,
body#ponentes,
// body#id_salas,
body.pop-up-block-scroll {
  overflow: hidden;
}

@media screen and (max-width: 767px), screen and (max-height: 850px) {
  body.pop-up-block-scroll {
      .bottom__networking {
          opacity: 0;
          z-index: 0;
      }    
  }
}

// Imagenes no dragables
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}


.pointer, .pointer * {
  cursor: pointer;
}

// Cerrar ventanas emergentes
app-cerrar-fancy {
  position: absolute;
  right: -1rem;
  border: 1px solid black;
  background: black;
  border-radius: 50%;
  padding: 1px;
  z-index: 1060;
  top: -1rem;
}

app-cerrar-emergente {
    position: absolute;
    right: 08px;
    border-radius: 50%;
    padding: 1px;
    z-index: 1060;

    svg { 
        fill: black;
        height: 12px;
        width: 12px;
    }
}


// Preloader
body {
  .preloader {
    display: none;
  }

  &.loading {
    .preloader {
      display: block;
    }
  }
}

// Scrollbar

// Chrome
body::-webkit-scrollbar {
  background-color: transparent;
  width: 0;
}

// Firefox
html {
  scrollbar-width: none;
}

.my-scrollbar {
  padding-right: 0.2rem;
  scrollbar-color: var(--solid) transparent;
  scrollbar-width: thin;

  // ::-webkit-scrollbar
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 4px;
  }

  &.my-scrollbar-fat::-webkit-scrollbar {
    width: 15px;
  }

  // ::-webkit-scrollbar-track
  &::-webkit-scrollbar-track {
    background-color: var(--grey-clear);
  }

  // ::-webkit-scrollbar-thumb
  &::-webkit-scrollbar-thumb {
    background-color: var(--solid);
    border: 1px solid var(--solid);
    border-radius: 2px;
  }

  &.my-scrollbar-square::-webkit-scrollbar-thumb {
    border-radius: 0;
  }

  &.my-scrollbar-clear::-webkit-scrollbar-thumb {
    background-color: lightgrey;
    border: 1px solid lightgrey;
  }
  
  &.my-scrollbar-primary::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border: 1px solid var(--primary);
  }
  
  &.my-scrollbar-light::-webkit-scrollbar-thumb {
    background-color: var(--primary-light);
    border: 1px solid var(--primary-light);
  }
  
  &.my-scrollbar-accent::-webkit-scrollbar-thumb {
    background-color: var(--accent);
    border: 1px solid var(--accent);
  }
  
}